<template>
  <AppModal
    ref="modal"
    content-class="w-dvw h-dvh"
    mobile-full-screen
    :close-if-change-route="closeIfChangeRoute"
  >
    <template #header>
      <div
        class="flex flex-row items-center absolute inset-x-0 top-0 p-4 h-14 bg-white/75 backdrop-blur-[10px]"
      >
        <div @click="close">
          <AppIcon
            class="size-6"
            name="com:close"
          />
        </div>
        <div
          class="grow flex flex-row items-center justify-center pointer-events-none"
        >
          <div class="text-h3 leading-6 text-center">{{ caption }}</div>
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-3 md:gap-6 pt-14 px-4 h-dvh">
      <div
        class="flex flex-col justify-stretch gap-6 grow px-4 -mx-4 overflow-y-scroll overflow-x-hidden"
        :class="{ 'pb-6': disablePaddingTop, 'py-6': !disablePaddingTop }"
      >
        <slot />
      </div>

      <div
        class="flex gap-6 px-4 pb-10 pt-3 bg-white/75 backdrop-blur-[10px]"
        :class="{
          'flex-col-reverse': actionsReverse,
          'flex-col': !actionsReverse
        }"
        @click="onActionBlockClick"
      >
        <slot name="actions">
          <AppButton
            v-if="okText"
            :text="okText"
            size="big"
            @click="onOk"
            :loading="isSubmitting"
            :disabled="okDisabled"
          />
          <AppButton
            :view="cancelView"
            v-if="cancelText"
            size="big"
            :text="cancelText"
            @click="onCancel"
            :disabled="cancelDisabled"
            :loading="cancelSubmitting"
          />
        </slot>
      </div>
    </div>
  </AppModal>
</template>

<script setup lang="ts">
import AppModal from '../components/AppModal.vue'
import AppButton from '../components/AppButton.vue'
import { ref, toRef, watch } from 'vue'
import AppIcon from '../components/AppIcon.vue'
import type { IModalEmits, IModalMobile } from '~common/models/Modal'

type ModalInstance = InstanceType<typeof AppModal>

const props = withDefaults(defineProps<IModalMobile>(), {
  cancelView: 'outline'
})

const emit = defineEmits<IModalEmits>()

/* ------------------------------------------------------------------------------------------------------------------ */

const modal = ref<ModalInstance>()

function onActionBlockClick() {
  tryClose(props.closeOnActionClick, props.isSubmitting)
}

watch(
  [toRef(props, 'closeOnActionClick'), toRef(props, 'isSubmitting')],
  ([closeOnActionClick, isSubmitting]) => {
    tryClose(closeOnActionClick, isSubmitting)
  }
)

function tryClose(closeOnActionClick: boolean, isSubmitting: boolean) {
  if (closeOnActionClick && !isSubmitting) {
    modal.value?.close()
  }
}

function onOk() {
  emit('onOk')
}

function onCancel() {
  emit('onCancel')
}

function close() {
  modal.value?.close()
}

defineExpose({
  close
})
</script>
