import { useCookies } from '@vueuse/integrations/useCookies'
import { storeToRefs } from 'pinia'
import uesUISettingsStore from '../stores/uiSettings'
import { computed } from 'vue'

const cookieDomainName = import.meta.env.PUBLIC_COOKIE_DOMAIN

export const useReferral = () => {
  const cookies = useCookies(['referral'])
  const { uiSettings } = storeToRefs(uesUISettingsStore())

  return {
    setCode: (code: string) => {
      cookies.set('referral', code, {
        path: '/',
        domain: cookieDomainName,
        maxAge: 60 * 60 * 24 * 365 // 365 day
      })
    },
    getCode: (): string => {
      return cookies.get('referral') || ''
    },
    removeCode: () => {
      cookies.remove('referral')
    },
    getReferralLink: (code: string) => {
      return computed(() => {
        return `${uiSettings.value?.links.companyName}?ref=${code}`
      })
    }
  }
}
