<template>
  <AppModalStandardWrapperDesktop
    v-if="$screen.gt.sm"
    caption-center
    v-bind="$props"
    fixed-header
    fixed-footer
    close-if-change-route
    v-on="{ onOk, onCancel, close }"
  >
    <slot />
  </AppModalStandardWrapperDesktop>

  <AppModalStandardWrapperMobile
    v-else
    v-bind="$props"
    close-if-change-route
    v-on="{ onOk, onCancel, close }"
  >
    <slot />
  </AppModalStandardWrapperMobile>
</template>

<script setup lang="ts">
import AppModalStandardWrapperDesktop from './AppModalStandardWrapperDesktop.vue'
import AppModalStandardWrapperMobile from './AppModalStandardWrapperMobile.vue'
import type { IModalDesktop, IModalEmits, IModalMobile } from '../models/Modal'

withDefaults(defineProps<IModalDesktop & IModalMobile>(), {})

const emit = defineEmits<IModalEmits>()

/* ------------------------------------------------------------------------------------------------------------------ */

function onOk() {
  emit('onOk')
}

function onCancel() {
  emit('onCancel')
}

function close() {
  emit('close')
}
</script>
