import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IWLJivoSettings } from '../models/WhiteLabel'
import jivoApi from '../api/jivo.api'

export const useJivoSettingsStore = defineStore('jivo-settings', () => {
  const jivoSettings = ref<IWLJivoSettings>()

  const fetchJivoSettings = async (domain?: string) => {
    jivoSettings.value = await jivoApi.fetchSettings(domain)
  }

  return {
    jivoSettings,
    fetchJivoSettings
  }
})
