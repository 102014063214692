<route>
{ meta: { guideName: 'home', header: 'full' } }
</route>

<template>
  <component :is="$screen.lt.md ? indexMobile : indexDesktop" />
</template>

<script setup lang="ts">
import { defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import swiftApi from '~/api/swift.api'
import { useNotify } from '~/common/src/composables/notify'
import { useModals } from '~/common/src/composables/useModals'
import SwiftConfirmModal from '~/components/swift/SwiftConfirmModal.vue'

defineOptions({
  name: 'IndexRoute'
})

const route = useRoute()
const { notifyError } = useNotify()
const modals = useModals()

const indexDesktop = defineAsyncComponent(
  () => import('~/components/pages/desktop/index.vue')
)

const indexMobile = defineAsyncComponent(
  () => import('~/components/pages/mobile/index.vue')
)

const handleConfirmSwift = async (pk: number) => {
  try {
    await swiftApi.confirmSwift(pk)

    modals.show(SwiftConfirmModal)
  } catch (error) {
    notifyError(error)
  }
}

watch(
  () => route.query,
  val => {
    const swiftId = val['confirm-swift']

    if (!swiftId) return

    handleConfirmSwift(+swiftId)
  },
  { immediate: true }
)
</script>
