import { httpClient } from '~common/plugins/httpClient'
import type {
  ICreateSwiftWithdrawPayload,
  IWithdrawalSwiftUploadDocResponse,
  IWithdrawalSwiftTemplateDeleteResponse,
  IWithdrawalSwiftTemplate,
  IGetSwiftPayload,
  ISwiftListResponse,
  IPatchSwift,
  ISwiftItem,
  ISwiftProviderPayment,
  IFetchSwiftPaymentsParams,
  IUpdateProviderPaymentPayload,
  IExportProviderRequisitesPayload,
  ISwiftTemplateParams
} from '~/models/Swift'
import type { IData } from '~/common/src/models/Api'
import { downloadFile } from '~/common/src/utils/downloadFile'
import { crossPlatformDownloadFile } from '~/utils/cross-platform/downloadFile'
import type {
  IDownloadFilePayload,
  ISwiftFile
} from '~/common/src/models/Swift'

export default {
  async createSwiftWithdraw(payload: ICreateSwiftWithdrawPayload) {
    return await httpClient.post<ISwiftItem>('/withdrawals/swift', payload)
  },

  async getSwift(id: number) {
    const result = await httpClient.get<ISwiftItem>('/withdrawals/swift/' + id)

    return result.data
  },

  async getSwiftList(payload: IGetSwiftPayload) {
    const result = await httpClient.get<ISwiftListResponse>(
      '/withdrawals/swift',
      {
        params: payload
      }
    )

    return result.data
  },

  async updateSwift(id: number | undefined, payload: IPatchSwift) {
    if (id === undefined) {
      console.error('You must specify the sfift id')

      return
    }

    return await httpClient.patch<ISwiftItem>(
      '/withdrawals/swift/' + id,
      payload
    )
  },

  async uploadFile(pk: number, file: File) {
    const formData = new FormData()

    formData.append(`swfit.doc.${file.name}`, file, file.name)

    return await httpClient<IWithdrawalSwiftUploadDocResponse>({
      method: 'post',
      url: `/withdrawals/swift/${pk}/files`,
      data: formData
    })
  },

  async fetchFiles(pk: number) {
    const result = await httpClient.get<IData<ISwiftFile>>(
      `/withdrawals/swift/${pk}/files`,
      { params: { per_page: 20 } }
    )

    return result.data.items
  },

  async downloadFile({ pk, file_id, filename }: IDownloadFilePayload) {
    await downloadFile({
      url: `/withdrawals/swift/${pk}/files/${file_id}`,
      name: filename
    })
  },

  async getWithdrawalSwiftTemplates(params: ISwiftTemplateParams) {
    const result = await httpClient.get<IData<IWithdrawalSwiftTemplate>>(
      '/withdrawals/swift-templates',
      { params }
    )

    return result.data
  },

  async deleteWithdrawalSwiftTemplate(id: number) {
    const result =
      await httpClient.delete<IWithdrawalSwiftTemplateDeleteResponse>(
        `/withdrawals/swift-templates/${id}`
      )

    return result.data
  },

  async fetchProviderPayments(params: IFetchSwiftPaymentsParams) {
    const result = await httpClient.get<IData<ISwiftProviderPayment>>(
      '/provider/withdrawals/swift',
      { params }
    )

    return result.data
  },

  async updateProviderPayment(payload: IUpdateProviderPaymentPayload) {
    const result = await httpClient.patch<ISwiftProviderPayment>(
      `/provider/withdrawals/swift/${payload.id}`,
      payload
    )

    return result.data
  },

  async exportProviderRequisites({
    pk,
    format
  }: IExportProviderRequisitesPayload) {
    await crossPlatformDownloadFile({
      url: `provider/withdrawals/swift/${pk}/export/${format}`,
      name:
        format === 'pdf'
          ? `swift-${pk}-requisites.pdf`
          : `swift-${pk}-requisites.csv`
    })
  },

  async confirmSwift(pk: number) {
    await httpClient.post('/withdrawals/confirm-swift', undefined, {
      params: { pk }
    })
  },

  async uploadProviderFile(pk: number, file: File) {
    const formData = new FormData()

    formData.append(`provider.swfit.doc.${file.name}`, file, file.name)

    return await httpClient<IWithdrawalSwiftUploadDocResponse>({
      method: 'post',
      url: `/provider/withdrawals/swift/${pk}/files`,
      data: formData
    })
  },

  async fetchProviderFiles(pk: number) {
    const result = await httpClient.get<IData<ISwiftFile>>(
      `/provider/withdrawals/swift/${pk}/files`,
      { params: { per_page: 20 } }
    )

    return result.data.items
  },

  async downloadProviderFile({ pk, file_id, filename }: IDownloadFilePayload) {
    await downloadFile({
      url: `/provider/withdrawals/swift/${pk}/files/${file_id}`,
      name: filename
    })
  }
}
