import { computed } from 'vue'
import { useAuthStore } from '~/common/src/stores/auth'

export default () => {
  const authStore = useAuthStore()

  const p2pUnreadDealsIds = computed(
    () => authStore.uiSettings.p2pUnreadDealsIds || []
  )

  const addUnreadDealId = (id: number) => {
    authStore.updateUiSettings({
      p2pUnreadDealsIds: [...p2pUnreadDealsIds.value, id]
    })
  }

  const removeUnreadDealId = (dealId: number) => {
    const filteredDeals = p2pUnreadDealsIds.value.filter(id => id !== dealId)

    authStore.updateUiSettings({ p2pUnreadDealsIds: filteredDeals })
  }

  const uniqUnreadDealsIds = computed(() => [
    ...new Set(p2pUnreadDealsIds.value)
  ])

  const isUnreadDeals = computed(() => !!uniqUnreadDealsIds.value.length)

  const isUnreadDeal = (id: number) => {
    return uniqUnreadDealsIds.value.includes(id)
  }

  return {
    addUnreadDealId,
    removeUnreadDealId,
    uniqUnreadDealsIds,
    isUnreadDeals,
    isUnreadDeal
  }
}
