import { defineStore } from 'pinia'
import { useI18n } from '~common/composables/useI18n'
import swiftApi from '~/api/swift.api'
import useWatchAuthenticated from '~common/composables/useWatchAuthenticated'
import useNotify from '~common/composables/notify/useNotify'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import useList from '~common/composables/useList'
import type { TSwiftStatus } from '~/common/src/models/Withdrawal'
import useCurrency from '~common/stores/currency.ts'

export default defineStore('swift-list', () => {
  const { notifyError, notifySuccess } = useNotify(notifyNeedEdit)
  const router = useRouter()
  const { t } = useI18n()
  const currencyStore = useCurrency()

  const {
    fetch,
    dataRef,
    pagination,
    filters,
    loading,
    temporaryDisableAutoFetch
  } = useList(swiftApi.getSwiftList)

  temporaryDisableAutoFetch.value = true

  const needUserAction = computed(() => dataRef.value?.items || [])

  filters.status = 'need_user_action'
  pagination.per_page = 20

  const needUserActionCount = computed(() => {
    return needUserAction.value.length
  })

  watch(needUserActionCount, (value, oldValue) => {
    if (value > oldValue) {
      notifyNeedEdit()
    }
  })

  useWatchAuthenticated(fetch)

  function notifyNeedEdit(force = false) {
    if (!force && !needUserAction.value.length) {
      return
    }

    const symbol = currencyStore.currencyIdMap.get(
      needUserAction.value[0].currency_id
    )?.symbol_url

    notifyError(t('app.WalletWithdrawPage.swiftNeedEditAppNotifyMessage'), {
      timeout: 15000,
      btnText: t('app.WalletWithdrawPage.swiftNeedEditAppNotifyBtn'),
      handlerClick() {
        router
          .push({
            name: 'withdraw',
            params: {
              currency: symbol,
              method: 'swift'
            }
          })
          .then()
      },
      closeOnClick: true
    })
  }

  function notifyStatusChange(status: TSwiftStatus) {
    notifySuccess(
      t('app.WalletWithdrawPage.swiftStatusChangeNotifyMessage', {
        status: t(`common.ledgerOperations.swift.status.${status}`)
      }),
      {
        timeout: 10000
      }
    )
  }

  return {
    refresh: fetch,
    needUserAction,
    swiftListLoading: loading,
    notifyStatusChange
  }
})
