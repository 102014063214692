/* eslint-disable @typescript-eslint/no-explicit-any  */
import type { Config, YaMetrika } from './types'

export function getMetrikaInstance(
  config: Config | Record<string, any>
): YaMetrika {
  const instance = new window.Ya.Metrika2(config)

  setMetrikaInstance(instance, config)

  return instance
}

function setMetrikaInstance(
  instance: YaMetrika,
  config: Config | Record<string, any>
) {
   
  // @ts-ignore
  window[`yaCounter${config.id}`] = instance
}
